<template>
  <div
    class="
      expanded-width
      prevent-scrollbar
      flex
      overflow-auto
      uppercase
      mt-6
      border-t-2 border-b-2 border-black
      p-3
    "
  >
    <div class="w-full mx-auto">
      <div class="flex items-center sm:text-base text-xs">
        <span class="border border-gray-800 rounded-full py-1 px-2 mx-3"
          >Skills</span
        >

        <div class="flex space-x-3 font-medium items-center">
          <div>Nuxt</div>
          <div>CSS</div>
          <div>Vue</div>
          <div>SASS</div>
          <div>StoryBlok</div>
          <div>Nuxt</div>
          <div>HTML</div>
          <div>JavaScript</div>
          <div>Tailwind</div>
          <div>CSS</div>
          <span class="border border-gray-800 rounded-full py-1 px-2 mx-3"
            >Skills</span
          >
          <div>Vue</div>
          <div>SASS</div>
          <div>StoryBlok</div>
          <div>Nuxt</div>
          <div>HTML</div>
          <div>JavaScript</div>
          <div>Tailwind</div>
          <div>CSS</div>
          <div>Vue</div>
          <div>SASS</div>
          <div>StoryBlok</div>
          <div>Nuxt</div>
          <div>HTML</div>
          <div>CSS</div>
          <div>Vue</div>
          <div>SASS</div>
          <div>StoryBlok</div>
          <div>Nuxt</div>
          <div>HTML</div>
          <div>CSS</div>
          <div>Vue</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.expanded-width {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
}

.prevent-scrollbar::-webkit-scrollbar {
  display: none;
}
</style>