<template>
  <div class="mb-32 px-5 sm:px-10 md:px-10 xl:px-0 z-10 relative">
    <h4 class="border-b border-black text-right text-xl font-medium pb-1">
      Oscar Wilde
    </h4>
    <div class="flex sm:flex-row flex-col mt-4">
      <p class="sm:w-1/2 w-full text-2xl leading-9">
        “Education is an admirable thing, but it is well to remember from time
        to time that nothing that is worth knowing can be taught.”
      </p>
      <div class="sm:w-1/2 w-full sm: sm:mt-0 mt-6 flex justify-end items-end">
        <div class="flex">
          <div class="rounded-full bg-black w-10 h-10"></div>
          <div class="rounded-full bg-black w-10 h-10"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>